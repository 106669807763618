import React from 'react';
import {Button, Container} from "react-bootstrap";
import {Link, Navigate, useLocation} from 'react-router-dom';
import {FaArrowCircleUp} from "react-icons/fa";
import {formatYear} from "./calendarUtil";
import {getDayLink, getMonthLink, getYearLink} from "../../appUtil";
import Day from "./Day";
import Month from "./Month";
import Year from "./Year";
import {getMomentDate, isValid} from "../../moment/momentFormat";
import {useTranslation} from "react-i18next";


function Calendar() {
    useTranslation();

    let passedValue = useLocation().pathname.slice(1)
    let validYear = isValidYear(passedValue);
    let validMonth = isValidYearMonth(passedValue);
    let validDay = isValidYearMonthDay(passedValue);
    let calculatedDateValue = calculateDate(validYear, validMonth, validDay, passedValue);
    let calculatedDate = new Date(calculatedDateValue);

    if (validDay) {
        let currentDayName = formatDay(calculatedDate);

        let nextDay = addDay(calculatedDateValue, true);
        let nextDayName = formatDay(nextDay);
        let nextMonthLink = getDayLink(nextDay);

        let previousDay = addDay(calculatedDateValue, false);
        let previousDayName = formatDay(previousDay);
        let previousDayLink = getDayLink(previousDay);

        return (
            <div>
                <Container fluid className="navMenu">
                    {createNavigationButton(previousDayLink, previousDayName)}
                    {createNavigationButton(getMonthLink(calculatedDate), <FaArrowCircleUp/>)}
                    {createNavigationButton(nextMonthLink, nextDayName)}
                </Container>
                <Container fluid>
                    <h3 style={mainDateStyle}>{currentDayName}</h3>
                    <Day date={calculatedDate}/>
                </Container>
            </div>
        );
    }

    if (validMonth) {
        let currentMonthName = formatMonth(getMomentDate(calculatedDate));

        let nextMonth = addMonth(calculatedDateValue, true);
        let nextMonthName = formatMonth(nextMonth);
        let nextMonthLink = getMonthLink(nextMonth);

        let previousMonth = addMonth(calculatedDateValue, false);
        let previousMonthName = formatMonth(previousMonth);
        let previousMonthLink = getMonthLink(previousMonth);

        return (
            <div>
                <Container fluid className="navMenu">
                    {createNavigationButton(previousMonthLink, previousMonthName)}
                    {createNavigationButton(getYearLink(calculatedDate), <FaArrowCircleUp/>)}
                    {createNavigationButton(nextMonthLink, nextMonthName)}
                </Container>
                <Container fluid>
                    <h3 style={mainDateStyle}>{currentMonthName}</h3>
                    <Month date={calculatedDate}/>
                </Container>
            </div>
        );
    }

    if (validYear) {
        let currentYearName = formatYear(getMomentDate(calculatedDate));
        let nextYear = addYear(calculatedDateValue, true);
        let nextYearName = formatYear(nextYear);
        let nextYearLink = getYearLink(nextYear);

        let previousYear = addYear(calculatedDateValue, false);
        let previousYearName = formatYear(previousYear);
        let previousYearLink = getYearLink(previousYear);

        return (
            <div>
                <Container fluid className="navMenu">
                    {createNavigationButton(previousYearLink, previousYearName)}
                    {createNavigationButton(nextYearLink, nextYearName)}
                </Container>
                <Container fluid>
                    <h3 style={mainDateStyle}>{currentYearName}</h3>
                    <Year date={calculatedDate}/>
                </Container>
            </div>
        );
    }
    return (<Navigate push to={"/nie-znaleziono"}/>)
}

const mainDateStyle = {
    fontWeight: 'bold',
};

function createNavigationButton(link, name) {
    return <Link aria-label={"link "+name} to={link}><Button aria-label={"button " + name} variant="success">{name}</Button></Link>
}

function isValidYear(str) {
    if (!isValid(str, "YYYY")) {
        return false;
    }
    if (!(parseInt(str) > 1000)) {
        console.log("years below 1001 are not yet supported");
        return false;
    }
    return true;
}

function isValidYearMonth(str) {
    return isValid(str, "YYYY-MM");
}

function isValidYearMonthDay(str) {
    return isValid(str, "YYYY-MM-DD");
}

function calculateDate(validYear, validMonth, validDay, passedValue) {
    let calculatedDate = new Date().getFullYear();
    if (validYear || validMonth || validDay) {
        calculatedDate = passedValue;
    }
    return calculatedDate;
}

function addYear(calculatedDate, add) {
    return addSingle(calculatedDate, add, 'year');
}

function addMonth(calculatedDate, add) {
    return addSingle(calculatedDate, add, 'months');
}

function addDay(calculatedDate, add) {
    return addSingle(calculatedDate, add, 'day');
}

function addSingle(calculatedDate, add, unit) {
    if (add) {
        return getMomentDate(new Date(calculatedDate)).add(1, unit);
    } else {
        return getMomentDate(new Date(calculatedDate)).subtract(1, unit);
    }
}

function formatMonth(month) {
    return month.format("MMMM YYYY");
}

function formatDay(day) {
    return getMomentDate(day).format("D MMMM YYYY");
}

export default Calendar;