import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {currentMonthLink} from "../../appUtil";
import FlagSelector from "./FlagSelector";
import { useTranslation } from 'react-i18next';
import {FaCalendar, FaSearch} from "react-icons/fa";

function AppNavbar() {
    const { t } = useTranslation();

    return (
        <Navbar bg="dark" variant="dark" expand="md">
            <Container fluid={true} className="topcontainer">
                <Navbar.Brand href={currentMonthLink}>{t('navbar.calendar')} <FaCalendar/></Navbar.Brand>
                <Navbar.Brand href={"/imieniny"}>{t('nameday')} <FaSearch/></Navbar.Brand>;
                <Navbar.Toggle/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link className="navbarlink" href="/info">{t('navbar.info')}</Nav.Link>
                        <Nav.Item><FlagSelector/></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AppNavbar;