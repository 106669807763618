import {getMonthNames} from "../../moment/momentFormat";
import namedaysPl from "../../locales/namedays.pl.json";

export function getDisplayItems() {
    let months = getMonthNames();
    let items = [];
    for (let i = 1; i <= 12; i++) {
        let x = i;
        if (i.toString().length < 2) {
            x = "0" + i;
        }
        for (let j = 1; j <= 31; j++) {
            let y = j;
            if (j.toString().length < 2) {
                y = "0" + j;
            }
            let names = namedaysPl[x][y];
            if (names !== undefined) {
                names = names.join(", ");
                //TODO translate day number
                items.push({"day": j + " " + months[i-1], "names": names, "key": x + y.toString()})
            }
        }
    }
    return items;
}