import React from 'react';
import {Table} from "reactstrap";
import {formatYear, getDaysTable, getWeekdaysRow} from "./calendarUtil";
import {getMomentDate, getMonthNames} from "../../moment/momentFormat";
import {formatMonthNumber, getMonthLink} from "../../appUtil";
import {Link, useLocation} from "react-router-dom";

function Year() {
    let passedValue = useLocation().pathname.slice(1);
    let yearNumber = new Date(passedValue).getFullYear();
    let currentYearName = formatYear(getMomentDate(passedValue));
    let weekdaysRow = getWeekdaysRow();
    let monthNames = getMonthNames();

    return monthNames.map((monthName, i) => {
        let monthNumber = formatMonthNumber(i);
        let currentMonthLink = getMonthLink(yearNumber+"-"+monthNumber);
        return (
            <div key={(i + 1)} className="months">
                <Table className="mt-4">
                    <thead>
                        <tr>
                            <th colSpan={7}>
                                <Link style={{ textDecoration: 'none' }} to={currentMonthLink}>{monthName} {currentYearName}</Link>
                            </th>
                        </tr>
                        <tr>
                            {weekdaysRow}
                        </tr>
                    </thead>
                    <tbody>
                        {getDaysTable(new Date(yearNumber, monthNumber, 0))}
                    </tbody>
                </Table>
            </div>
        );
    });
}

export default Year;