import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'moment/locale/hi';
import {getCountry} from "../appUtil";

export function setMomentLocale() {
    moment.locale(getCountry());
}

/**
 * 0 is Sunday, 6 is Saturday
 * in Poland, the first day is Monday, so this function will return 1,
 * in GB, the first day is Sunday, so this function will return 0
 */
export function getLocaleFirstWeekDay(){
    return moment().localeData().firstDayOfWeek();
}

export function getWeekdayNames() {
    return moment.weekdaysMin(true);
}

export function getMonthNames() {
    return moment.months();
}

export function getMomentDate(givenDate) {
    return moment(givenDate);
}

export function isValid(str, format) {
    return moment(str, format, true).isValid();
}

