import {getI18n} from "react-i18next";

export const langToCountry = { 'en': 'GB', 'pl': 'PL', 'hi': "IN" };

export let currentMonthLink = getMonthLink(new Date());

export function getCountry() {
    return getI18n().language.slice(0,2);
}

export function getCountryPl() {
    return "pl";
}

export function getLangShort() {
    return getI18n().language.slice(3,5);
}

export function getYearLink(year) {
    return "/" + new Date(year).getFullYear();
}

export function getMonthLink(month) {
    let date = new Date(month);
    return getYearLink(month) + "-" + formatMonthNumber(date.getMonth());
}

export function getDayLink(day) {
    let date = new Date(day).getDate();
    return getMonthLink(day) + '-' + formatTo2Digits(date.toString());
}

export function formatMonthNumber(monthNumber) {
    let number = (monthNumber + 1);
    return formatTo2Digits(number);
}

function formatTo2Digits(number) {
    return String(number).padStart(2, '0')
}

export function addLinks(names) {
    let split = names.split(", ");
    return split.map((name, index) => {
        if (index === split.length - 1) {
            return (<>
                <a className="nameLink" href={"/imieniny/" + name}>
                    {name}
                </a>
            </>)
        } else {
            return (<>
                <a className="nameLink" href={"/imieniny/" + name}>
                    {name}
                </a>
                , &#8203;
            </>)
        }
    });
}