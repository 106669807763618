import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Input} from "reactstrap";
import {getDisplayItems} from "./namedayUtil";
import {addLinks} from "../../appUtil";


function Namedays() {

    const [searchName, setSearchName] = useState("");
    const [searchDate, setSearchDate] = useState("");
    const { t } = useTranslation();

    return (
        <div className="name-day-table">
            <h3>
                {t('nameday')}
            </h3>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th><Input onChange={(e) => setSearchDate(e.target.value)}></Input></th>
                        <th><Input onChange={(e) => setSearchName(e.target.value)}></Input></th>
                    </tr>
                </thead>
                <tbody>
                    {getDisplayItems()
                        .filter((row) =>
                            !searchName.length || row.names
                                .toString()
                                .toLowerCase()
                                .includes(searchName.toString().toLowerCase())
                        )
                        .filter((row) =>
                            !searchDate.length || row.day
                                .toString()
                                .toLowerCase()
                                .includes(searchDate.toString().toLowerCase())
                        )
                        .map((item) => {
                            return (
                                <tr key={Math.random()}>
                                    <th key={"d" + item.key}>{item.day}</th>
                                    <th key={"n" + item.key}>
                                        {addLinks(item.names)}
                                    </th>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
}

export default Namedays;