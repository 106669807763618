import {useTranslation} from "react-i18next";
import {CookieConsent, getCookieConsentValue} from "react-cookie-consent";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";


function Cookie() {

    const { t } = useTranslation();

    const handleAcceptCookie = () => {
        if (window.location.origin.includes("kalendarzimienin")) {
            ReactGA.initialize("G-206MSP09K3");
        } else {
            console.log("cookie not initialized")
        }
    };

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();
        }
    }, []);

    return (
        <div className="Cookie">
            <CookieConsent
                location="top"
                onAccept={handleAcceptCookie}
                buttonText={t('cookie.accept')}
            >
                <Link style={{ textDecoration: 'none', color: 'white' }} to={"/info"}>{t('cookie.siteUsesCookies')}</Link>
            </CookieConsent>
        </div>
    );
}

export default Cookie;