import React from 'react';
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getFunHolidays, getHolidays, getNamedays} from "./calendarUtil";


function Day() {
    let passedValue = useLocation().pathname.slice(1);
    const { t } = useTranslation();

    return (
        <div>
            {getHolidays(passedValue)}
            {getFunHolidays(passedValue, t)}
            {getNamedays(passedValue, t, true)}
        </div>
    );
}

export default Day;