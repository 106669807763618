import React from 'react';
import {Table} from "reactstrap";
import {getDaysTable, getFunHolidays, getHolidays, getNamedays, getWeekdaysRow} from "./calendarUtil";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getDayLink} from "../../appUtil";

function Month() {
    let passedValue = useLocation().pathname.slice(1);
    const { t } = useTranslation();
    return (
        <div>
            <Table className="mt-4">
                <thead>
                    <tr>
                        {getWeekdaysRow()}
                    </tr>
                </thead>
                <tbody>
                    {getDaysTable(new Date(passedValue))}
                </tbody>
            </Table>
            {showHolidays(passedValue, t)}
            {showFunHolidays(passedValue, t)}
            {showNamedays(passedValue, t)}
            <br/>
        </div>
    );
}

function showHolidays(passedValue, t) {
    if (isCurrentMonth(passedValue)) {
        return getHolidays(getDayLink(new Date()).slice(1), t);
    }
}

function showFunHolidays(passedValue, t) {
    if (isCurrentMonth(passedValue)) {
        return getFunHolidays(getDayLink(new Date()).slice(1), t);
    }
}

function showNamedays(passedValue, t) {
    if (isCurrentMonth(passedValue)) {
        return getNamedays(getDayLink(new Date()).slice(1), t);
    }
}

function isCurrentMonth(passedValue) {
    let currentDate = new Date();
    let passedDate = new Date(passedValue + "-01");
    if (currentDate.getFullYear() === passedDate.getFullYear()) {
        if (currentDate.getMonth() === passedDate.getMonth()) {
            return true;
        }
    }
    return false;
}

export default Month;