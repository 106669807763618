import './App.css';
import {BrowserRouter, Navigate, Routes, Route} from 'react-router-dom';
import Calendar from "./component/calendar/Calendar";
import Info from "./component/info/Info";
import NotFound from "./component/error/NotFound";
import Namedays from "./component/nameday/Namedays";
import Nameday from "./component/nameday/Nameday";
import AppNavbar from "./component/navbar/AppNavbar";
import Cookie from "./component/cookie/Cookie";
import {currentMonthLink} from "./appUtil";
import {setMomentLocale} from "./moment/momentFormat";


function App() {
    setMomentLocale();
    return (
        <div className="App">
            <BrowserRouter>
                <AppNavbar/>
                <Cookie/>
                <Routes>
                    <Route path='sitemap.xml'/>
                    <Route path='' element={<Navigate to={currentMonthLink}/>} />
                    <Route path='info' element={<Info/>}/>
                    <Route path='imieniny/:name' element={<Nameday/>}/>
                    <Route path='imieniny' element={<Namedays/>}/>
                    <Route path='nie-znaleziono' element={<NotFound/>}/>
                    <Route path=':date' element={<Calendar/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;
