import {getLocaleFirstWeekDay, getMomentDate, getWeekdayNames} from "../../moment/momentFormat";
import {addLinks, getCountry, getCountryPl, getDayLink} from "../../appUtil";
import namedaysPl from "../../locales/namedays.pl.json";
import funHolidaysPl from "../../locales/funholidays.pl.json";
import funHolidaysEn from "../../locales/funholidays.en.json";
import Holidays from "date-holidays";
import React from "react";
import i18n from "i18next";

export function formatYear(year) {
    return year.format("YYYY");
}

export function getWeekdaysRow() {
    return getWeekdayNames().map(day => {
        return (
            <th key={day} className="week-day">
                {day}
            </th>
        );
    })
}

export function getDaysTable(calculatedDate) {
    let dayNumbers = getDayNumbersInMonth(calculatedDate);
    let dayCells = addStyling(dayNumbers, calculatedDate);
    return createColumnsAndRows(dayCells);
}

export function getHolidays(passedValue) {
    let holidays = new Holidays(getCountryPl());
    holidays.setLanguages(getCountry())
    let holiday = holidays.isHoliday(passedValue);
    if (holiday) {
        return (
            <h3 style={{color: "darkred"}}>
            <br/>
                {holiday.map((e) => {
                    return e.name + " ";
                })}
            </h3>
        )
    }
}

export function getFunHolidays(passedValue, t) {
    let funHolidays = null;
    if (i18n.resolvedLanguage === 'pl') {
        funHolidays = JSON.parse(JSON.stringify(funHolidaysPl));
    } else {
        funHolidays = JSON.parse(JSON.stringify(funHolidaysEn));
    }
    let month = passedValue.slice(5,7)
    let day = passedValue.slice(8,10)
    let funHolidayElement = funHolidays[month][day];
    if (funHolidayElement !== undefined) {
        let names = funHolidayElement.join(", ")
        return (<>
            <br/>
            <h4 style={{fontWeight: 'bold'}}>{t('funHolidays')}</h4>
            <h4>{names}</h4>
        </>)
    }
    return "";
}

export function getNamedays(passedValue, t) {
    let namedays = JSON.parse(JSON.stringify(namedaysPl));
    if (namedays !== null) {
        let month = passedValue.slice(5,7)
        let day = passedValue.slice(8,10)
        let names = namedays[month][day].join(", ")
        return (<>
            <br/>
            <h4 style={{fontWeight: 'bold'}}>{t('nameday')}</h4>
            <h4>{addLinks(names)}</h4>
        </>)
    }
    return "";
}

function getDayNumbersInMonth(passedDate) {
    let firstDayInWeekOfMonth = new Date(passedDate.getFullYear(), passedDate.getMonth(), 1).getDay();
    let lastDayOfMonth = new Date(passedDate.getFullYear(), passedDate.getMonth() + 1, 0).getDate();
    let dayNumbers = [];

    let emptyCells = firstDayInWeekOfMonth + (7 - getLocaleFirstWeekDay());
    if (emptyCells > 6 ) {
        emptyCells = emptyCells - 7;
    }
    for (let i = 0; i < emptyCells; i++) {
        dayNumbers.push(100+i);
    }
    for (let i = 1; i <= lastDayOfMonth; i++) {
        dayNumbers.push(i);
    }
    let leftEmptyCellsInWeek = (dayNumbers.length % 7) !== 0 ? 7 - (dayNumbers.length % 7) : 0;
    for (let i = 0; i < leftEmptyCellsInWeek; i++) {
        dayNumbers.push(200+i);
    }
    return dayNumbers;
}

function addStyling(dayNumbers, passedDate) {
    let passedFullYear = passedDate.getFullYear();
    let passedMonth = passedDate.getMonth();
    let localPublicHolidayDates = getLocalPublicHolidayDates(passedFullYear);
    let todayDate = new Date();
    let currentMonth = (todayDate.getFullYear() === passedFullYear) && (todayDate.getMonth() === passedMonth);
    let currentDay = todayDate.getDate();
    let firstDayInWeekOfMonth = new Date(passedFullYear, passedMonth, 1).getDay();
    let styledCells = [];

    dayNumbers.forEach((dayNumber) => {
        if (dayNumber > 99) {
            styledCells.push(getDayCellEmpty(dayNumber));
        } else {
            let insertingDate = new Date(passedFullYear, passedMonth, dayNumber).toDateString();
            let dayLink = getDayLink(insertingDate);
            let formattedDay = formatDayNumber(insertingDate);
            let localPublicHoliday = localPublicHolidayDates.includes(insertingDate);
            let sunday = firstDayInWeekOfMonth !== 0 ? (firstDayInWeekOfMonth + dayNumber) % 7 === 1 : dayNumber % 7 === 1;
            let holiday = sunday || localPublicHoliday;
            let today = currentMonth && (dayNumber === currentDay);

            if (today && holiday) {
                styledCells.push(
                    getDayCell(dayNumber, dayLink, formattedDay, "calendar-day-today calendar-day-holiday")
                );
            } else if (holiday) {
                styledCells.push(
                    getDayCell(dayNumber, dayLink, formattedDay, "calendar-day-holiday")
                );
            } else if (today) {
                styledCells.push(
                    getDayCell(dayNumber, dayLink, formattedDay, "calendar-day-today")
                );
            } else {
                styledCells.push(
                    getDayCell(dayNumber, dayLink, formattedDay, "calendar-day")
                );
            }
        }
    });
    return styledCells;
}

function createColumnsAndRows(dayCells) {
    let rows = [];
    let cells = [];

    dayCells.forEach((cell, i) => {
        if (i % 7 !== 0) {
            cells.push(cell);
        } else {
            rows.push(cells);
            cells = [];
            cells.push(cell);
        }
        if (i === dayCells.length - 1) {
            rows.push(cells);
        }
    });

    return rows.map((r, i) => {
        return <tr key={i}>{r}</tr>;
    });
}

function formatDayNumber(day) {
    return  getMomentDate(day).date();
}

function getLocalPublicHolidayDates(passedFullYear) {
    let holidays = new Holidays(getCountryPl());
    return holidays.getHolidays(passedFullYear)
        .filter(holiday => holiday.type === "public")
        .map(holiday => new Date(holiday.date).toDateString());
}

function getDayCell(dayNumber, dayLink, formattedDay, className) {
    return (
        <td key={dayNumber} className={className}>
            <a href={dayLink}>
                {formattedDay}
            </a>
        </td>
    );
}

function getDayCellEmpty(dayNumber) {
    return (
        <td key={dayNumber} className="calendar-day-empty"></td>
    );
}