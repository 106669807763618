import {useTranslation} from "react-i18next";

function NotFound() {
    const { t } = useTranslation();
    return (
        <div>
            <br/>
            <h3 className="text-center">
                {t('notfound.notFound')}
            </h3>
        </div>
    );
}

export default NotFound;