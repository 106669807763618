import React from 'react';
import { useTranslation } from 'react-i18next';
import {getCookieConsentValue, resetCookieConsentValue} from "react-cookie-consent";
import {Link} from "react-router-dom";

function Info() {
    const { t } = useTranslation();

    function getResetLink() {
        if (getCookieConsentValue() === "true") {
            return (
                <div>
                    <br/>
                    <Link onClick={() => resetCookieConsentValue()} reloadDocument to={"/info"}>{t('info.resetCookieConsent')}</Link>
                </div>
            )
        }
    }

    return (
        <div>
            <br/>
            <h5 className="info">
                {t('info.readGooglePrivacy')}
                <a target="_blank" rel="noopener noreferrer"
                   href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                <br/>
                {t('info.youCanDisableCookies')}
                {getResetLink()}
            </h5>
        </div>
    );
}

export default Info;