import ReactFlagsSelect from 'react-flags-select';
import React, {useState} from 'react';
import {getLangShort, langToCountry} from "../../appUtil";
import {setMomentLocale} from "../../moment/momentFormat";
import i18next from "i18next";


function FlagSelector() {
    const [selected, setSelected] = useState(getLangShort());
    return (
        <ReactFlagsSelect
            onSelect={countryCode => onSelectFlag(countryCode)}
            selected={selected}
            countries={getCountries()}
            showSelectedLabel={false}
            fullWidth={false}
            alignOptionsToRight
        />
    );

    function getCountries() {
        return Object.values(langToCountry);
    }
    function onSelectFlag(countryCode){
        setSelected(countryCode)
        let lang = Object.keys(langToCountry).find(key => langToCountry[key] === countryCode);
        i18next.changeLanguage(lang + "-" + countryCode)
        setMomentLocale();
    }
}

export default FlagSelector;