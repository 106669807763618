import React from "react";
import {useLocation} from "react-router-dom";
import {getDisplayItems} from "./namedayUtil";
import namesMeaningPl from "../../locales/names.meaning.pl.json";
import namesMeaningEn from "../../locales/names.meaning.en.json";
import i18n from "i18next";


function Nameday() {

    let passedValue = useLocation().pathname.split('/').at(2);
    let nameValue = decodeURIComponent(passedValue);

    return (<>
        <br/>
        <h4 style={{fontWeight: 'bold'}}>{nameValue.toUpperCase()}</h4>
            {getNameMeaning(nameValue)}
            <br/>
            {getDisplayItems()
                .filter((row) =>
                    !nameValue.length || row.names
                        .toString()
                        .toLowerCase()
                        .includes(nameValue.toString().toLowerCase())
                )
                .map((item) => (
                    <h5>
                        <div key={"dd" + item.key}>
                            <a className="fromNameLink" href={getDayLinkByKey(item.key)}>
                                {item.day}
                            </a>
                        </div>
                    </h5>
                ))}
            <br/>
        </>
    )
}

function getDayLinkByKey(key) {
    return "/" + new Date().getFullYear() + "-" + key.slice(0,2) + "-" + key.slice(2,4);
}

function getNameMeaning(nameValue) {
    let namesMeaning = null;
    if (i18n.resolvedLanguage === 'pl') {
        namesMeaning = JSON.parse(JSON.stringify(namesMeaningPl));
    } else {
        namesMeaning = JSON.parse(JSON.stringify(namesMeaningEn));
    }
    let foundNameDes = namesMeaning[nameValue];
    if (foundNameDes !== undefined) {
        return (<>
            <br/>
            <div className="nameDescription">{foundNameDes}</div>
        </>)
    }
    return "";
}

export default Nameday;